import React from "react";

import { ReactComponent as GearIcon } from "../../../assets/icons/gear.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { ReactComponent as HumanIcon } from "../../../assets/icons/human.svg";
import { ReactComponent as BackwardIcon } from "../../../assets/icons/backward.svg";
import { ReactComponent as ForwardIcon } from "../../../assets/icons/forward.svg";
import { ReactComponent as PencilIcon } from "../../../assets/icons/pencil.svg";
import { ReactComponent as GlassPlusIcon } from "../../../assets/icons/glass_plus.svg";
import { ReactComponent as GlassMinusIcon } from "../../../assets/icons/glass_minus.svg";
import { ReactComponent as PanIcon } from "../../../assets/icons/pan.svg";
import { ReactComponent as SunIcon } from "../../../assets/icons/sun.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/dropdown.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as RulerIcon } from "../../../assets/icons/ruler.svg";
import { ReactComponent as RayIcon } from "../../../assets/icons/ray.svg";
import { ReactComponent as AngleIcon } from "../../../assets/icons/angle.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/image_settings.svg";

type IconProps = {
  name:
    | "gearIcon"
    | "homeIcon"
    | "humanIcon"
    | "backwardIcon"
    | "forwardIcon"
    | "pencilIcon"
    | "glassPlusIcon"
    | "glassMinusIcon"
    | "panIcon"
    | "sunIcon"
    | "menuIcon"
    | "dropdownIcon"
    | "addIcon"
    | "rulerIcon"
    | "rayIcon"
    | "angleIcon"
    | "settingsIcon";
};

const MyIcon = (props: IconProps) => {
  const { name, ...svgProps } = props;

  const Icons: Record<IconProps["name"], any> = {
    gearIcon: <GearIcon {...svgProps} />,
    homeIcon: <HomeIcon {...svgProps} />,
    humanIcon: <HumanIcon {...svgProps} />,
    backwardIcon: <BackwardIcon {...svgProps} />,
    forwardIcon: <ForwardIcon {...svgProps} />,
    pencilIcon: <PencilIcon {...svgProps} />,
    glassPlusIcon: <GlassPlusIcon {...svgProps} />,
    glassMinusIcon: <GlassMinusIcon {...svgProps} />,
    panIcon: <PanIcon {...svgProps} />,
    sunIcon: <SunIcon {...svgProps} />,
    menuIcon: <MenuIcon {...svgProps} />,
    dropdownIcon: <DropdownIcon {...svgProps} />,
    addIcon: <AddIcon {...svgProps}/>,
    rulerIcon: <RulerIcon {...svgProps}/>,
    rayIcon: <RayIcon {...svgProps}/>,
    angleIcon: <AngleIcon {...svgProps}/>,
    settingsIcon: <SettingsIcon {...svgProps}/>,
  };

  return Icons[name];
};

export default MyIcon;
