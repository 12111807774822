import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useToggle, upperFirst } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Checkbox,
  Anchor,
  Stack,
} from "@mantine/core";

import { useActions } from "../../app/hooks";
import { NavLink } from "react-router-dom";

export default function AuthenticationForm() {
  const { logIn } = useActions();

  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const [type, toggle] = useToggle(["login", "register"]);
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      terms: true,
    },

    validate: {
      email: (val: string) =>
        /^\S+@\S+$/.test(val) ? null : "Неверный формат почты",
      password: (val: string) =>
        val.length <= 6 ? "Пароль должен состоять минимум из 6 символов" : null,
    },
  });

  async function submitUserForm() {
    if (type === "register") {
      await window.electron.user
        .register({
          email: form.values.email,
          password: form.values.password,
        })
        .then(() => {
          toggle();
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (type === "login") {
      await window.electron.user
        .login({
          username: form.values.email,
          password: form.values.password,
        })
        .then((response) => {
          logIn(response.token);
          navigate("/");
        })
        .catch((e) => {
          console.log(e);
          if (e.response?.data?.detail === "Invlaid password") {
            setErrorText("Неправильный логин или пароль");
          } else if (e.response?.data?.detail?.includes("not found")) {
            setErrorText("Пользователь не найден");
          } else {
            setErrorText("Сервер не отвечает");
          }
          console.log(e);
        });
    }
  }

  return (
    <Paper radius="md" p="xl" withBorder>
      <Text size="lg" fw={500}>
        Добро пожаловать в OrthoHelper, пожалуйста
        {type === "login" ? " войдите" : " зарегистрируйтесь"}
      </Text>

      <form
        onSubmit={form.onSubmit(submitUserForm)}
        // onError={(e) => console.log("error")}
      >
        <Stack mt="sm">
          <TextInput
            required
            label="Электронная почта"
            placeholder="orthodontist@gmail.com"
            value={form.values.email}
            onChange={(event) => {
              form.setFieldValue("email", event.currentTarget.value);
              setErrorText("");
            }}
            error={(form.errors.email && "Неверный формат почты") || errorText}
            radius="md"
          />

          <PasswordInput
            required
            label="Пароль"
            placeholder="Ваш пароль"
            value={form.values.password}
            onChange={(event) => {
              form.setFieldValue("password", event.currentTarget.value);
              setErrorText("");
            }}
            error={
              form.errors.password &&
              "Пароль должен состоять минимум из 6 символов"
            }
            radius="md"
          />

          {type === "register" && (
            <Checkbox
              label="Я принимаю условия использования"
              checked={form.values.terms}
              onChange={(event) =>
                form.setFieldValue("terms", event.currentTarget.checked)
              }
            />
          )}
        </Stack>

        <Group mt="xl">
          <Anchor
            component="button"
            type="button"
            c="dimmed"
            onClick={() => toggle()}
            size="xs"
          >
            {type === "register"
              ? "Уже есть аккаунт? Войти"
              : "Еще нет аккаунта? Зарегистрироваться"}
          </Anchor>
          <Button type="submit" radius="xl">
            {upperFirst(type)}
          </Button>
        </Group>
      </form>
      <NavLink to={"/login/select"}>
        <Button>Выбрать пользователя</Button>
      </NavLink>
    </Paper>
  );
}
