// HeaderControls.tsx
import React, { useState } from "react";
import { Button, Menu, Switch, Slider } from "@mantine/core";
import Icon from "../../components/UI/Icon/Icon";
import styles from "./EditorBar.module.scss";

interface HeaderProps {
  onUndo: () => void;
  onRedo: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onToggleMode: (mode: "drag" | "point" | "draw" | "ruler" | "angle") => void;
  onToggleSidebar: () => void;
  setHidePoints: React.Dispatch<React.SetStateAction<boolean>>;
  setHideLines: React.Dispatch<React.SetStateAction<boolean>>;
  setHideDraw: React.Dispatch<React.SetStateAction<boolean>>;
  hidePoints: boolean;
  hideLines: boolean;
  hideDraw: boolean;
  setHideImage: React.Dispatch<React.SetStateAction<boolean>>;
  setFlipImage: React.Dispatch<React.SetStateAction<boolean>>;
  setImageContrast: React.Dispatch<React.SetStateAction<number>>;
  setImageSaturation: React.Dispatch<React.SetStateAction<number>>;
  hideImage: boolean;
  flipImage: boolean;
  imageContrast: number;
  imageSaturation: number;
}

const HeaderControls: React.FC<HeaderProps> = ({
  onUndo,
  onRedo,
  onZoomIn,
  onZoomOut,
  onToggleMode,
  onToggleSidebar,
  setHidePoints,
  setHideLines,
  setHideDraw,
  hidePoints,
  hideLines,
  hideDraw,
  setHideImage,
  setFlipImage,
  setImageContrast,
  setImageSaturation,
  hideImage,
  flipImage,
  imageContrast,
  imageSaturation,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <header className={styles.headerEditor}>
      <div className={styles.leftEditor}>
        <Menu
          opened={opened}
          onChange={setOpened}
          shadow="md"
          width={200}
          position="bottom-start"
        >
          <Menu.Target>
            <Button className={styles.buttonEditor}>
              <Icon name="settingsIcon" />
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <p>Яркость:</p>
            <Slider 
              style={{marginBottom: "10px"}}
              min={1}
              max={100}
              value={imageSaturation}
              onChange={setImageSaturation}
            />
            <p>Контрастность:</p>
            <Slider 
              style={{marginBottom: "10px"}}
              max={100}
              value={imageContrast}
              onChange={setImageContrast}
            />
            <div style={{display: "flex", justifyContent: "start", marginBottom: "10px"}}>
              <p>Инвертировать</p>
              <Switch 
                style={{marginLeft: "20px"}}
                checked={flipImage}
                onChange={(event) => setFlipImage(event.currentTarget.checked)}
              />
            </div>
            <div style={{display: "flex", justifyContent: "start", marginBottom: "10px"}}>
              <p>Скрыть изображение</p>
              <Switch 
                style={{marginLeft: "20px"}}
                checked={hideImage}
                onChange={(event) => setHideImage(event.currentTarget.checked)}
              />
            </div>
          </Menu.Dropdown>
        </Menu>
        <Button className={styles.buttonEditor} onClick={onUndo}>
          <Icon name="backwardIcon" />
        </Button>
        <Button className={styles.buttonEditor} onClick={onRedo}>
          <Icon name="forwardIcon" />
        </Button>
      </div>
      <div className={styles.rightEditor}>
        <Button className={styles.buttonEditor} onClick={onZoomIn}>
          <Icon name="glassPlusIcon" />
        </Button>
        <Button className={styles.buttonEditor} onClick={onZoomOut}>
          <Icon name="glassMinusIcon" />
        </Button>
        <Button
          className={styles.buttonEditor}
          onClick={() => onToggleMode("drag")}
        >
          <Icon name="panIcon" />
        </Button>
        <Button
          className={styles.buttonEditor}
          onClick={() => onToggleMode("point")}
        >
          <Icon name="sunIcon" />
        </Button>
        <Button
          className={styles.buttonEditor}
          onClick={() => onToggleMode("draw")}
        >
          <Icon name="pencilIcon" />
        </Button>
        <Button
          className={styles.buttonEditor}
          onClick={() => onToggleMode("ruler")}
        >
          <Icon name="rulerIcon" />
        </Button>
        <Button
          className={styles.buttonEditor}
          onClick={() => onToggleMode("angle")}
        >
          <Icon name="angleIcon" />
        </Button>
        <Button
          className={styles.buttonEditor}
          style={{ lineHeight: "20px" }}
          onClick={() => setHidePoints((p) => !p)}
        >
          {hidePoints ? "Показать" : "Скрыть"} <br /> точки
        </Button>
        <Button
          className={styles.buttonEditor}
          style={{ lineHeight: "20px" }}
          onClick={() => setHideLines((l) => !l)}
        >
          {hideLines ? "Показать" : "Скрыть"} <br /> линии
        </Button>
        <Button
          className={styles.buttonEditor}
          style={{ lineHeight: "20px" }}
          onClick={() => setHideDraw((d) => !d)}
        >
          {hideDraw ? "Показать" : "Скрыть"} <br /> отрисовку
        </Button>
        <Button className={styles.buttonEditor} onClick={onToggleSidebar}>
          <Icon name="menuIcon" />
        </Button>
      </div>
    </header>
  );
};

export default HeaderControls;