/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import {
  Radio,
  Stack,
  Checkbox,
  Button,
  ScrollArea,
  Box,
  Modal,
} from "@mantine/core";
import Icon from "../../components/UI/Icon/Icon";
import styles from "./EditorBar.module.scss";
import { NavigateFunction } from "react-router-dom";

type Point = {
  name: string;
  description: string;
  serializedCountMethod: string;
};
type CalculationMethod = { calcMethod: string; label: string; points: Point[] };

interface SidebarProps {
  calculationMethodPoints: CalculationMethod[];
  setCalculationMethodPoints: React.Dispatch<
    React.SetStateAction<CalculationMethod[]>
  >;
  checked: boolean[];
  setChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
  checkedItems: Point[];
  setCheckedItems: React.Dispatch<React.SetStateAction<Point[]>>;
  dropdownOpen: string | null;
  setDropdownOpen: React.Dispatch<React.SetStateAction<string | null>>;
  isCreatingMethod: boolean;
  setIsCreatingMethod: React.Dispatch<React.SetStateAction<boolean>>;
  isMethodSelected: boolean;
  setIsMethodSelected: React.Dispatch<React.SetStateAction<boolean>>;
  isCalculating: boolean;
  setIsCalculating: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMethod: {
    points: Point[] | null;
    calcMethod: string;
    label: string;
  } | null;
  setSelectedMethod: React.Dispatch<
    React.SetStateAction<{
      points: Point[] | null;
      calcMethod: string;
      label: string;
    } | null>
  >;
  handleSelectedRadioValue: (value: string) => void;
  handleCheckboxChange: (point: Point) => void;
  handleMethodCheckboxChange: (methodName: string, index: number) => void;
  userId: string | number | undefined;
  windowSize: number[];
  navigate: NavigateFunction;
  id: string | undefined;
  TRH_id: string | undefined;
  points: {
    x: number;
    y: number;
    order: number;
    text: string;
    type: string;
    degree: number;
  }[];
  trhName: string | undefined;
  photoPath: string | undefined;
  calculatedParameters: {
    anomaly: string | null;
    norm: string;
    value: string;
  }[];
  setCalculateParameters: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarContent: React.FC<SidebarProps> = ({
  calculationMethodPoints,
  setCalculationMethodPoints,
  checked,
  setChecked,
  checkedItems,
  setCheckedItems,
  dropdownOpen,
  setDropdownOpen,
  isCreatingMethod,
  setIsCreatingMethod,
  isMethodSelected,
  setIsMethodSelected,
  isCalculating,
  setIsCalculating,
  selectedMethod,
  handleSelectedRadioValue,
  handleCheckboxChange,
  handleMethodCheckboxChange,
  userId,
  windowSize,
  navigate,
  id,
  TRH_id,
  points,
  trhName,
  photoPath,
  calculatedParameters,
  setCalculateParameters,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const toggleDropdown = (value: string) => {
    setDropdownOpen((prev) => (prev === value ? null : value));
  };

  const toggleMethodCreating = () => {
    setCheckedItems([]);
    setIsCreatingMethod((m) => !m);
  };

  const handleCalculateParameters = () => {
    setCalculateParameters(true);
    setOpenModal(true);
  };

  const handleSelect = async () => {
    if (isCreatingMethod) {
      let createNew = false;
      if (checkedItems.length === 0) return;
      setCalculationMethodPoints((prev) => {
        if (prev.some((elem) => elem.calcMethod === "custom")) {
          createNew = false;
          return prev.map((elem) =>
            elem.calcMethod === "custom"
              ? { ...elem, points: checkedItems }
              : elem
          );
        } else {
          createNew = true;
          return [
            ...prev,
            {
              calcMethod: "custom",
              label: "Созданый метод",
              points: checkedItems,
            },
          ];
        }
      });
      if (createNew) {
        await window.electron.method.create(
          "custom",
          "Созданый метод",
          checkedItems,
          userId as number
        );
      }

      setIsCreatingMethod(false);
    } else if (selectedMethod) {
      await window.electron.method.choose(
        selectedMethod.calcMethod,
        userId as number
      );
      setIsMethodSelected(true);
    }
  };

  const handleTRHUpload = async () => {
    if (!id || !selectedMethod?.points?.length) return;
    try {
      if (!isNaN(parseInt(TRH_id as string)))
        await window.electron.TRH.update(parseInt(TRH_id as string), points);
      else
        await window.electron.TRH.upload(
          trhName ? trhName : "",
          parseInt(id),
          photoPath ? photoPath : "",
          points
        );
      navigate(`/patient/${id}`);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setChecked(() =>
      calculationMethodPoints.map((elem) =>
        elem.points.every((point) =>
          checkedItems.some(
            (item) =>
              item.name === point.name && item.description === point.description
          )
        )
      )
    );
  }, [checkedItems, calculationMethodPoints]);

  return (
    <>
      {!isMethodSelected && (
        <div className={styles.sidebar}>
          <div className={styles.sidebarText}>
            <h4>Выберите метод расчета:</h4>
          </div>
          <Radio.Group name="calculationMethod">
            <Stack mt="xl" ml="lg">
              {calculationMethodPoints.map((method, index) => (
                <div
                  key={method.calcMethod + index}
                  style={{ marginBottom: "10px" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {!isCreatingMethod && (
                      <Radio
                        value={method.calcMethod}
                        onClick={() =>
                          handleSelectedRadioValue(method.calcMethod)
                        }
                      />
                    )}
                    <p style={{ marginLeft: "20px" }}>{method.label}</p>
                    {isCreatingMethod && (
                      <>
                        <Checkbox
                          className={styles.checkbox}
                          radius="none"
                          style={{ marginLeft: "auto", marginBottom: "10px" }}
                          checked={checked[index] ?? false}
                          onChange={() =>
                            handleMethodCheckboxChange(method.calcMethod, index)
                          }
                        />
                        <Button
                          className={styles.dropdownButton}
                          onClick={() => toggleDropdown(method.calcMethod)}
                        >
                          <Icon name="dropdownIcon" />
                        </Button>
                      </>
                    )}
                    {!isCreatingMethod && (
                      <Button
                        className={styles.dropdownButton}
                        onClick={() => toggleDropdown(method.calcMethod)}
                        style={{ marginLeft: "auto", marginBottom: "10px" }}
                      >
                        <Icon name="dropdownIcon" />
                      </Button>
                    )}
                  </div>
                  {dropdownOpen === method.calcMethod && (
                    <div className={styles.dropdownContent}>
                      <Stack>
                        <ScrollArea h={(windowSize[1] / 100) * 20}>
                          <Box>
                            {method.points.map((point, index) => (
                              <div
                                key={`${point.name}-${index}`}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                  width: "100%",
                                }}
                              >
                                <p style={{ maxWidth: "80%" }}>
                                  <strong>{point.name}</strong> -{" "}
                                  {point.description}
                                </p>
                                {isCreatingMethod && (
                                  <Checkbox
                                    key={`${point.name}-${index}`}
                                    checked={checkedItems.some(
                                      (item) =>
                                        item.name === point.name &&
                                        item.description === point.description
                                    )}
                                    className={styles.checkbox}
                                    radius="none"
                                    onChange={() => handleCheckboxChange(point)}
                                    style={{ marginRight: "20px" }}
                                  />
                                )}
                              </div>
                            ))}
                          </Box>
                        </ScrollArea>
                      </Stack>
                    </div>
                  )}
                </div>
              ))}
            </Stack>
          </Radio.Group>
          {isCreatingMethod && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <p style={{ marginLeft: "15px" }}>Добавить свой параметр</p>
              <Button
                className={styles.dropdownButton}
                style={{ marginRight: "10px", marginTop: "10px" }}
              >
                <Icon name="addIcon" />
              </Button>
            </div>
          )}
          <div className={styles.sidebarText2}>
            <h5>
              Вы можете создать свой метод
              <br />
              расчета ТРГ на основе имеющихся,
              <br />
              добавляя нужные Вам параметры
            </h5>
            <Button mt="lg" onClick={toggleMethodCreating}>
              {isCreatingMethod ? "Отмена" : "Создать свой метод"}
            </Button>
            <Button mt="lg" ml="xs" onClick={handleSelect}>
              {isCreatingMethod ? "Создать" : "Выбрать"}
            </Button>
          </div>
        </div>
      )}
      {isMethodSelected && !isCalculating && (
        <div className={styles.sidebar}>
          <div
            className={styles.sidebarText}
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "7px",
            }}
          >
            Перед началом рассчета определите масштаб снимка и контур мягких
            тканей.
          </div>
          <h4
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            Задать масштаб:
          </h4>
          <div style={{ marginLeft: "10px", marginTop: "10px" }}>
            Поставьте точки M1 и M2 на линейке на расстоянии в 10 мм друг от
            друга
          </div>
          <div
            className={styles.precalcConditions}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              width: "90%",
            }}
          >
            Поставьте точку М1
            <Checkbox
              className={styles.checkbox}
              checked={points.some((elem) => elem.text === "M1")}
            />
          </div>
          <div
            className={styles.precalcConditions}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              width: "90%",
            }}
          >
            Поставьте точку М2
            <Checkbox
              className={styles.checkbox}
              checked={points.some((elem) => elem.text === "M2")}
            />
          </div>
          <h4
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            Выставить обрисовку мягких тканей:
          </h4>
          <div className={styles.precalcConditions} style={{ maxWidth: "80%" }}>
            Обрисуйте контуры мягких тканей инструментом Карандаш
          </div>
          <Button
            mt="lg"
            ml="lg"
            onClick={() => setIsCalculating(true)}
            disabled={!points.some((elem) => elem.text === "M2")}
          >
            Перейти к расчету
          </Button>
        </div>
      )}
      {isCalculating && (
        <div className={styles.sidebar}>
          <h4
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            Изменить метод рассчета
          </h4>
          <h4
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            Переключать на следущую точку автоматически
          </h4>
          <h4
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            Общие параметры:
          </h4>
          <div className={styles.precalcConditions}>Чета там</div>
          <div className={styles.precalcConditions}>Тамче то</div>
          <div className={styles.precalcConditions}>Чета чета</div>
          <h4
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            Точки:
          </h4>
          <div className={styles.dropdownContent}>
            <Stack>
              <ScrollArea h={(windowSize[1] / 100) * 20}>
                <Box>
                  {selectedMethod?.points?.map((point, index) => (
                    <div
                      key={`${point.name}-${index}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <p style={{ maxWidth: "80%" }}>
                        <strong>{point.name}</strong> - {point.description}
                      </p>
                      <Checkbox
                        className={styles.checkbox}
                        style={{ marginRight: "20px" }}
                        checked={points.some(
                          (elem) => elem.text === point.name
                        )}
                      />
                      {isCreatingMethod && (
                        <Checkbox
                          key={`${point.name}-${index}`}
                          checked={checkedItems.some(
                            (item) =>
                              item.name === point.name &&
                              item.description === point.description
                          )}
                          className={styles.checkbox}
                          radius="none"
                          onChange={() => handleCheckboxChange(point)}
                          style={{ marginRight: "20px" }}
                        />
                      )}
                    </div>
                  ))}
                </Box>
              </ScrollArea>
            </Stack>
          </div>
          <Button mt="lg" ml="lg" onClick={handleTRHUpload}>
            Готово
          </Button>
          <Button
            mt="lg"
            ml="md"
            onClick={handleCalculateParameters}
            disabled={points.length - 2 < selectedMethod!.points!.length!}
          >
            Посмотреть Отчет
          </Button>
        </div>
      )}
      <Modal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        title="Отчет"
        centered
        xOffset={0}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            
          }}
        >
          <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "10px",
                  width: "100%",
                  minWidth: "0",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Аномалия:
                </p>
                <p
                  style={{
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Норма:
                </p>
                <p
                  style={{
                    textAlign: "right",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Отклонение:
                </p>
              </div>
          {calculatedParameters
            .filter((elem1) => {
              return calculatedParameters.find(
                (elem2) => elem1.anomaly === elem2.anomaly
              );
            })
            .map((elem, index) => (
              <div
                key={`${elem.anomaly}-${index}`}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "10px",
                  width: "100%",
                  minWidth: "0",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {elem.anomaly}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {elem.norm}
                </p>
                <p
                  style={{
                    textAlign: "right",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {elem.value}
                </p>
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default SidebarContent;
