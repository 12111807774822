import React, { useEffect, useState } from "react";
import { Button, TextInput } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { Patient } from "../../types/types";

function UploadTRH() {
  interface FileWithPath extends File {
    path?: string;
  }

  const { id } = useParams();
  const [TRHName, setTRHName] = useState<string>("");
  const [photo, setPhoto] = useState<FileWithPath>();
  const [patient, setPatient] = useState<Patient>();

  const navigate = useNavigate();
  const generateDefaultTRHName = () => {
    const currentDate = new Date().toLocaleDateString("en-GB");
    // Assuming patientName is available
    const patientName = patient?.name; // Replace with actual patient name
    if (!patientName) return "";
    const initials = patientName
      .split(" ")
      .map((word) => word.charAt(0))
      .join("");
    return `${currentDate} - ${patientName} ${initials}`;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const selectedFile = e.target.files[0];

    if (
      selectedFile.type === "image/jpeg" ||
      selectedFile.type === "image/png"
    ) {
      setPhoto(selectedFile);
    }
  };

  useEffect(() => {
    const getPatient = async () => {
      const patient = await window.electron.patient.get(parseInt(id as string));
      setPatient(patient);
    };
    getPatient();
  }, []);

  useEffect(() => {
    const name = generateDefaultTRHName();
    setTRHName(name);
  }, [patient]);

  const handleCalculate = async () => {
    //rewrite
    //throw new Error(`${patient.id}`);
    console.log(id);
    if (!photo) return;
    navigate(`/patient/${patient?.id}/TRH/calculate`, {
      state: {
        photo: photo,
        photoPath: photo?.path,
        name: TRHName,
        patient: patient
      },
    });
  };

  return (
    <div>
      <h2>Рассчет ТРГ</h2>
      <TextInput
        value={TRHName}
        onChange={(e) => setTRHName(e.target.value)}
      />
      <input
        type="file"
        accept="image/jpeg, image/png"
        multiple
        onChange={handleFileChange}
        style={{ opacity: "0", height: 0, width: 0 }}
      />
      {photo && (
        <div>
          <img
            src={URL.createObjectURL(photo)}
            alt="Uploaded Photo"
            style={{ maxWidth: "500px", maxHeight: "500px", margin: "5px" }}
          />
        </div>
      )}
      <Button
        onClick={() =>
          (document.querySelector("input[type='file']") as HTMLElement).click()
        }
      >
        Добавить
      </Button>
      <Button onClick={handleCalculate}>Рассчитать</Button>
    </div>
  );
}

export default UploadTRH;
