import React, { useEffect, useState, useLayoutEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Stack } from "@mantine/core";
import CanvasComponent from "./CanvasComponent";
import HeaderControls from "./HeaderControls";
import SidebarContent from "./SidebarContent";

function CalculateTRH() {
  const location = useLocation();
  const photoPath = location.state?.photoPath;
  const initialTRHName = location.state?.name;
  const { TRH_id, id } = useParams();
  const navigate = useNavigate();

  type Point = { name: string; description: string, serializedCountMethod:string; };
  type CalculationMethod = { calcMethod: string; label: string; points: Point[] };

  const [pointOrder, setPointOrder] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [checked, setChecked] = useState<boolean[]>([]);
  const [points, setPoints] = useState<
    {
      x: number;
      y: number;
      order: number;
      text: string;
      type: string;
      degree: number;
    }[]
  >([]);
  const [redoBuffer, setRedoBuffer] = useState<
    {
      x: number;
      y: number;
      order: number;
      text: string;
      type: string;
      degree: number;
    }[]
  >([]);
  const [currentPoint, setCurrentPoint] = useState<{
    x: number;
    y: number;
    order: number;
    text: string;
    type: string;
    degree: number;
  } | null>(null);
  type Mode = "drag" | "point" | "draw" | "ruler" | "angle";
  const [mode, setMode] = useState<Mode>("drag");
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const [isCreatingMethod, setIsCreatingMethod] = useState<boolean>(false);
  const [isMethodSelected, setIsMethodSelected] = useState<boolean>(false);
  const [isCalculating, setIsCalculating] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | number>();
  const [calculationMethodPoints, setCalculationMethodPoints] = useState<
    CalculationMethod[]
  >([]);
  const [selectedMethod, setSelectedMethod] = useState<{
    points: Point[] | null;
    calcMethod: string;
    label: string;
  } | null>(null); // Removed undefined from type
  const [checkedItems, setCheckedItems] = useState<Point[]>([]);
  const [trhName, setTRHName] = useState<string | undefined>(initialTRHName);
  const [photo, setPhoto] = useState<File>();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [windowSize, setWindowSize] = useState([0, 0]);
  const [hidePoints, setHidePoints] = useState<boolean>(false);
  const [hideLines, setHideLines] = useState<boolean>(false);
  const [hideDraw, setHideDraw] = useState<boolean>(false);
  const [hideImage, setHideImage] = useState<boolean>(false);
  const [flipImage, setFlipImage] = useState<boolean>(false);
  const [imageContrast, setImageContrast] = useState<number>(0);
  const [imageSaturation, setImageSaturation] = useState<number>(0);
  const [calculatedParameters, setCalculatedParameters] = useState<{anomaly: string | null, norm: string, value: string}[]>([
    { anomaly: null, norm: "", value: "" }
  ]);
  const [calculateParameters, setCalculateParameters] = useState<boolean>(false);

  const headerHeight = 80;

  // Initialization and data fetching
  const initCalculationMethods = async () => {
    const currUser = await window.electron.user.get();
    const methods = await window.electron.method.getAll(currUser.id as number);
    setUserId(currUser.id);
    return methods;
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check for Ctrl+Z (Undo)
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault(); // Prevent browser's default undo behavior
        if (event.shiftKey) console.log("redo");
        handleUndo();
      }
      // Check for Ctrl+Shift+Z (Redo)
      if (event.ctrlKey && event.key === "x") {
        event.preventDefault(); // Prevent browser's default redo behavior
        handleRedo();
      }
    };

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [points, redoBuffer]);

  useEffect(() => {
    initCalculationMethods().then((data) => {
      setCalculationMethodPoints([...data]);
    });
  }, []);

  useEffect(() => {
    if (TRH_id && userId) {
      (async () => {
        const gotTRH = await window.electron.TRH.get(
          parseInt(TRH_id as string)
        );
        const allMethods = await window.electron.method.getAll(
          userId as number
        );
        const chosenMethod = allMethods.filter(
          (elem) => elem.isChosen === true
        );
        setPhoto(new Blob([gotTRH.image], { type: "image/jpeg" }) as File);
        setTRHName(gotTRH.name);
        setPoints(gotTRH.points);
        setSelectedMethod({
          calcMethod: chosenMethod[0].calcMethod,
          label: chosenMethod[0].label,
          points: chosenMethod[0].points,
        });
        setIsCalculating(true);
      })();
    } else {
      setPhoto(location.state?.photo);
    }
  }, [userId]);

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <Stack spacing={0}>
      <CanvasComponent
        photo={photo}
        windowSize={windowSize}
        headerHeight={headerHeight}
        position={position}
        setPosition={setPosition}
        scale={scale}
        setScale={setScale}
        points={points}
        currentPoint={currentPoint}
        isDragging={isDragging}
        setIsDragging={setIsDragging}
        isDrawing={isDrawing}
        setIsDrawing={setIsDrawing}
        offset={offset}
        setOffset={setOffset}
        mode={mode}
        pointOrder={pointOrder}
        setPointOrder={setPointOrder}
        setPoints={setPoints}
        setCurrentPoint={setCurrentPoint}
        setRedoBuffer={setRedoBuffer}
        hidePoints={hidePoints}
        hideLines={hideLines}
        hideDraw={hideDraw}
        hideImage={hideImage}
        flipImage={flipImage}
        imageContrast={imageContrast}
        imageSaturation={imageSaturation}
        selectedMethod={selectedMethod}
        setCalculatedParameters={setCalculatedParameters}
        calculateParameters={calculateParameters}
        setCalculateParameters={setCalculateParameters}
      />
      {sidebarOpen && (
        <SidebarContent
          calculationMethodPoints={calculationMethodPoints}
          setCalculationMethodPoints={setCalculationMethodPoints}
          checked={checked}
          setChecked={setChecked}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          isCreatingMethod={isCreatingMethod}
          setIsCreatingMethod={setIsCreatingMethod}
          isMethodSelected={isMethodSelected}
          setIsMethodSelected={setIsMethodSelected}
          isCalculating={isCalculating}
          setIsCalculating={setIsCalculating}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          handleSelectedRadioValue={handleSelectedRadioValue}
          handleCheckboxChange={handleCheckboxChange}
          handleMethodCheckboxChange={handleMethodCheckboxChange}
          userId={userId}
          windowSize={windowSize}
          navigate={navigate}
          id={id}
          TRH_id={TRH_id}
          points={points}
          trhName={trhName}
          photoPath={photoPath}
          calculatedParameters={calculatedParameters}
          setCalculateParameters={setCalculateParameters}
        />
      )}
      <HeaderControls
        onUndo={handleUndo}
        onRedo={handleRedo}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onToggleMode={toggleMode}
        onToggleSidebar={() => setSidebarOpen((prev) => !prev)}
        setHidePoints={setHidePoints}
        setHideLines={setHideLines}
        setHideDraw={setHideDraw}
        hidePoints={hidePoints}
        hideLines={hideLines}
        hideDraw={hideDraw}
        // Add new props
        setHideImage={setHideImage}
        setFlipImage={setFlipImage}
        setImageContrast={setImageContrast}
        setImageSaturation={setImageSaturation}
        hideImage={hideImage}
        flipImage={flipImage}
        imageContrast={imageContrast}
        imageSaturation={imageSaturation}
      />
    </Stack>
  );

  // Helper functions (previously in the main component)
  function handleUndo() {
    if (points.length === 0) return;
    const updatedPoints = [...points];

    if (!updatedPoints[updatedPoints.length - 1]) return;
    if (updatedPoints[updatedPoints.length - 1].type === "dummy") return;
    const undoPointOrder = updatedPoints[updatedPoints.length - 1].order;

    const addToRedoBuffer: {
      x: number;
      y: number;
      order: number;
      text: string;
      type: string;
      degree: number;
    }[] = [];

    while (
      updatedPoints.length > 0 &&
      updatedPoints[updatedPoints.length - 1].order === undoPointOrder &&
      updatedPoints[updatedPoints.length - 1].type !== "dummy"
    ) {
      const undonePoint = updatedPoints.pop();
      if (!undonePoint) return; // This is now redundant but kept for safety
      addToRedoBuffer.push(undonePoint);
    }
    if (addToRedoBuffer.length > 0)
      setRedoBuffer((prev) => [...prev, ...addToRedoBuffer]);
    setPoints(updatedPoints);
  }

function handleRedo() {
  if (redoBuffer.length === 0) return;
  const updatedRedo = [...redoBuffer];
  
  if (!updatedRedo[updatedRedo.length - 1].order) return;
  const redoPointOrder = updatedRedo[updatedRedo.length - 1].order;

  const addToPoints: {
    x: number;
    y: number;
    order: number;
    text: string;
    type: string;
    degree: number;
  }[] = [];
  while (updatedRedo.length > 0 && updatedRedo[updatedRedo.length - 1].order === redoPointOrder) {
      const redonePoint = updatedRedo.pop();
      if (!redonePoint) return; // Defensive check, though unlikely
      addToPoints.push(redonePoint);
  }
  if (addToPoints.length > 0)
      setPoints((prev) => [...prev, ...addToPoints]);
  setRedoBuffer(updatedRedo);
}
  function handleZoomIn() {
    const zoomFactor = 1.1;
    setScale((prevScale) => {
      const newScale = Math.max(0.1, Math.min(prevScale * zoomFactor, 5));
      setPosition((prevPos) => {
        const centerX = window.innerWidth / 2;
        const centerY = (window.innerHeight - headerHeight) / 2;
        return {
          x: centerX - (centerX - prevPos.x) * (newScale / prevScale),
          y: centerY - (centerY - prevPos.y) * (newScale / prevScale),
        };
      });
      return newScale;
    });
  }

  function handleZoomOut() {
    const zoomFactor = 0.9;
    setScale((prevScale) => {
      const newScale = Math.max(0.1, Math.min(prevScale * zoomFactor, 5));
      setPosition((prevPos) => {
        const centerX = window.innerWidth / 2;
        const centerY = (window.innerHeight - headerHeight) / 2;
        return {
          x: centerX - (centerX - prevPos.x) * (newScale / prevScale),
          y: centerY - (centerY - prevPos.y) * (newScale / prevScale),
        };
      });
      return newScale;
    });
  }

  function toggleMode(newMode: Mode) {
    setMode(newMode);
  }

  function handleSelectedRadioValue(value: string) {
    const foundMethod = calculationMethodPoints.find((elem) => elem.calcMethod === value);
    setSelectedMethod(foundMethod ? { points: foundMethod.points, calcMethod: value, label: foundMethod.label } : null);
  }

  function handleCheckboxChange(point: Point) {
    setCheckedItems((prev) => {
      const exists = prev.some(
        (item) => item.name === point.name && item.description === point.description
      );
      return exists
        ? prev.filter((item) => item.name !== point.name || item.description !== point.description)
        : [...prev, point];
    });
  }

  function handleMethodCheckboxChange(methodName: string, index: number) {
    setCheckedItems((prev) => {
      const methodPoints =
        calculationMethodPoints.find((elem) => elem.calcMethod === methodName)
          ?.points ?? [];
      const allChecked = methodPoints.every((point) =>
        prev.some(
          (item) =>
            item.name === point.name && item.description === point.description
        )
      );

      if (allChecked) {
        setChecked((prev) => {
          const newChecked = [...prev];
          newChecked[index] = false;
          return newChecked;
        });
        return prev.filter(
          (p) =>
            !methodPoints.some(
              (point) =>
                point.name === p.name && point.description === p.description
            )
        );
      } else {
        setChecked((prev) => {
          const newChecked = [...prev];
          newChecked[index] = true;
          return newChecked;
        });
        const newCheckedItems = [...prev];
        methodPoints.forEach((point) => {
          if (
            !newCheckedItems.some(
              (item) =>
                item.name === point.name &&
                item.description === point.description
            )
          ) {
            newCheckedItems.push(point);
          }
        });
        return newCheckedItems;
      }
    });
  }
}


export default CalculateTRH;
