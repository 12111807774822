/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// CanvasComponent.tsx
import React, { useEffect, useRef, useState } from "react";
import {acuteAngle, acuteAngleLines, perpendicularPoint, distanceBetweenPoints, linesIntersectionPoint, findParallelPoint, getIntersection, getAngleBetween, getAngleBetweenLines, normalize} from "./utils";

type Point = {
  name: string;
  description: string;
  serializedCountMethod: string;
};

interface CanvasProps {
  photo: File | undefined;
  windowSize: number[];
  headerHeight: number;
  position: { x: number; y: number };
  setPosition: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  points: {
    x: number;
    y: number;
    order: number;
    text: string;
    type: string;
    degree: number;
  }[];
  currentPoint: {
    x: number;
    y: number;
    order: number;
    text: string;
    type: string;
    degree: number;
  } | null;
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  isDrawing: boolean;
  setIsDrawing: React.Dispatch<React.SetStateAction<boolean>>;
  offset: { x: number; y: number };
  setOffset: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>;
  mode: "drag" | "point" | "draw" | "ruler" | "angle";
  pointOrder: number;
  setPointOrder: React.Dispatch<React.SetStateAction<number>>;
  setPoints: React.Dispatch<
    React.SetStateAction<
      {
        x: number;
        y: number;
        order: number;
        text: string;
        type: string;
        degree: number;
      }[]
    >
  >;
  setCurrentPoint: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
      order: number;
      text: string;
      type: string;
      degree: number;
    } | null>
  >;
  setRedoBuffer: React.Dispatch<
    React.SetStateAction<
      {
        x: number;
        y: number;
        order: number;
        text: string;
        type: string;
        degree: number;
      }[]
    >
  >;
  hidePoints: boolean;
  hideLines: boolean;
  hideDraw: boolean;
  hideImage: boolean;
  flipImage: boolean;
  imageContrast: number;
  imageSaturation: number;
  selectedMethod: {
    points: Point[] | null;
    calcMethod: string;
    label: string;
  } | null;
  setCalculatedParameters: React.Dispatch<
    React.SetStateAction<{ anomaly: string | null, norm: string, value: string }[]>
  >;
  calculateParameters: boolean;
  setCalculateParameters: React.Dispatch<React.SetStateAction<boolean>>;
}

const CanvasComponent: React.FC<CanvasProps> = ({
  photo,
  windowSize,
  headerHeight,
  position,
  setPosition,
  scale,
  setScale,
  points,
  currentPoint,
  isDragging,
  setIsDragging,
  isDrawing,
  setIsDrawing,
  offset,
  setOffset,
  mode,
  pointOrder,
  setPointOrder,
  setPoints,
  setCurrentPoint,
  setRedoBuffer,
  hidePoints,
  hideLines,
  hideDraw,
  hideImage,
  flipImage,
  imageContrast,
  imageSaturation,
  selectedMethod,
  setCalculatedParameters,
  calculateParameters,
  setCalculateParameters,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [newLine, setNewLine] = useState<boolean>(true); // Changed initial value to true
  const [gotOrder, setGotOrder] = useState<boolean>(true);
  const [realLength, setRealLength] = useState<number>(1);

  useEffect(() => {
    if (!photo) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    const img = new Image();
    img.src = URL.createObjectURL(photo);

    img.onload = () => {
      imgRef.current = img;
      const initialPosition = {
        x: (canvas.width - img.width * scale) / 2,
        y: (canvas.height - img.height * scale) / 2,
      };
      setPosition(initialPosition);
      drawImage(
        ctx,
        img,
        scale,
        initialPosition,
        flipImage,
        hideImage,
        imageContrast,
        imageSaturation
      );
    };
  }, [photo, windowSize]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx || !imgRef.current) return;
    drawImage(
      ctx,
      imgRef.current,
      scale,
      position,
      flipImage,
      hideImage,
      imageContrast,
      imageSaturation
    );
  }, [
    scale,
    position,
    points,
    currentPoint,
    hideDraw,
    hideLines,
    hidePoints,
    flipImage,
    hideImage,
    imageContrast,
    imageSaturation,
    calculateParameters,
  ]);

  useEffect(() => {
    if (points && points.length > 0 && gotOrder) {
      setPointOrder(points[points.length - 1].order);
      setGotOrder(false);
    }
  }, [points]);

  useEffect(() => {
    if (points.length === 0) {
      setPoints((p) => [
        ...p,
        {
          x: 0,
          y: 0,
          order: -1,
          text: "Insert Text",
          type: "dummy",
          degree: -1,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (points.length > 1) {
      points.forEach((elem, index) => {
        if (index === 2) points[index].text = "M1";
        if (index === 3) {
          points[index].text = "M2";
          setRealLength(
            Math.sqrt(
              Math.pow(points[3].x - points[2].x, 2) +
                Math.pow(points[3].y - points[2].y, 2)
            )
          );
        }
      });
    }
  }, [points, currentPoint]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      const rect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      setScale((prevScale) => {
        const zoomFactor = e.deltaY < 0 ? 1.1 : 0.9;
        const newScale = Math.max(0.1, Math.min(prevScale * zoomFactor, 5));
        setPosition((prevPos) => {
          const dx = mouseX - prevPos.x;
          const dy = mouseY - prevPos.y;
          return {
            x: mouseX - dx * (newScale / prevScale),
            y: mouseY - dy * (newScale / prevScale),
          };
        });
        return newScale;
      });
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    return () => window.removeEventListener("wheel", handleWheel);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const drawImage = (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    scale: number,
    pos: { x: number; y: number },
    flipImage: boolean,
    hideImage: boolean,
    imageContrast: number,
    imageSaturation: number
  ) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    if (!hideImage) {
      ctx.save();
      ctx.filter = `brightness(${imageSaturation + 100}%) contrast(${
        imageContrast + 100
      }%)`;
      if (flipImage) {
        ctx.scale(-1, 1);
        const flippedX = -(pos.x + img.width * scale);
        ctx.drawImage(
          img,
          flippedX,
          pos.y,
          img.width * scale,
          img.height * scale
        );
      } else {
        ctx.drawImage(img, pos.x, pos.y, img.width * scale, img.height * scale);
      }
      ctx.restore();
    }

    const pointsCopy = [...points];
    pointsCopy.sort((a, b) => {
      const nameA = a.type.toUpperCase();
      const nameB = b.type.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    // Filter "point" type points for selectedMethod assignment
    const methodPoints = pointsCopy.filter((p) => p.type === "point");
    if (selectedMethod?.points) {
      if (calculateParameters) {
        selectedMethod.points.forEach((p) => {
          const parsedMethod = JSON.parse(p.serializedCountMethod).parameters;
          if (parsedMethod) {
            const gotValue = evaluatePipe(parsedMethod);
            let gotAnomaly: string | null = null;
            if (parsedMethod.equal) {
              if (gotValue.toFixed(1) < parsedMethod.equal)
                gotAnomaly = parsedMethod.anomalySmaller;
              if (gotValue.toFixed(1) > parsedMethod.equal)
                gotAnomaly = parsedMethod.anomalyBigger;
            } else if (parsedMethod.lowerLimit && parsedMethod.upperLimit) {
              if (gotValue.toFixed(1) < parsedMethod.lowerLimit)
                gotAnomaly = parsedMethod.anomalySmaller;
              if (gotValue.toFixed(1) > parsedMethod.upperLimit)
                gotAnomaly = parsedMethod.anomalyBigger;
            }
            console.log(gotAnomaly);
            if (gotAnomaly && gotValue) {
              setCalculatedParameters((arr) => [
                ...arr,
                {
                  anomaly: gotAnomaly,
                  norm: parsedMethod.equal
                    ? "=" + parsedMethod.equal.toString()
                    : ">" +
                      parsedMethod.lowerLimit
                        .toString()
                        .concat(" <", parsedMethod.upperLimit.toString()),
                  value: gotValue.toFixed(1).toString()
                },
              ]);
            }
          }
        });
        setCalculateParameters(false);
      }

      methodPoints.forEach((point, methodIndex) => {
        if (
          methodIndex < selectedMethod!.points!.length &&
          selectedMethod!.points![methodIndex]?.serializedCountMethod
        ) {
          point.text =
            selectedMethod!.points![methodIndex]?.name ?? "Insert Text";
        }
      });
    }

    const drawPoint = (
      posX: number,
      posY: number,
      color: string,
      radius: number
    ) => {
      ctx.beginPath();
      ctx.arc(posX, posY, radius, 0, 2 * Math.PI);
      ctx.fillStyle = color;
      ctx.fill();
    };

    // Process all points in a single pass
    pointsCopy.forEach((point, index, array) => {
      const pointX = pos.x + point.x * scale;
      const pointY = pos.y + point.y * scale;
      let color = "red"; // Default color

      // Determine visibility and color based on type
      if (point.type === "drawpoint" && hideDraw) return;
      if (
        (point.type === "point" ||
          point.type.startsWith("ruler") ||
          point.type.startsWith("angle")) &&
        hidePoints
      )
        return;
      if (point.type === "ray2" && hideLines) return;

      if (point.type === "ray2") color = "purple";

      // Draw the point once
      if (
        point.type === "point" ||
        point.type === "ruler1" ||
        point.type === "ruler2" ||
        point.type === "angle1" ||
        point.type === "angle2" ||
        point.type === "angle3"
      )
        drawPoint(pointX, pointY, color, 5);

      // Draw text once (customize based on type)
      ctx.font = "12px Arial";
      ctx.fillStyle = "black";
      let text = `(${point.x.toFixed(0)}, ${point.y.toFixed(0)}, ${
        point.order
      }, ${point.text})`;
      if (point.type === "ray2") {
        text = `(${point.x.toFixed(0)}, ${point.y.toFixed(0)}, ${
          point.order
        }, ${point.type}, ${point.text})`;
      }
      if (
        point.type === "point" ||
        point.type === "ruler1" ||
        point.type === "ruler2" ||
        point.type === "angle1" ||
        point.type === "angle2" ||
        point.type === "angle3"
      )
        ctx.fillText(text, pointX + 7, pointY + 7);

      // Handle connections and additional rendering
      if (point.type === "drawpoint" && !hideDraw) {
        const prevPoint = array[index - 1];
        if (
          prevPoint &&
          prevPoint.order === point.order &&
          prevPoint.type === "drawpoint"
        ) {
          const prevX = pos.x + prevPoint.x * scale;
          const prevY = pos.y + prevPoint.y * scale;
          ctx.strokeStyle = "green";
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(prevX, prevY);
          ctx.lineTo(pointX, pointY);
          ctx.stroke();
        }
      }

      if (point.type === "point" && selectedMethod?.points && !hideLines) {
        const methodIndex = methodPoints.indexOf(point);
        if (
          methodIndex >= 0 &&
          methodIndex < selectedMethod.points.length &&
          selectedMethod.points[methodIndex]?.serializedCountMethod
        ) {
          const serializedMethod = JSON.parse(
            selectedMethod.points[methodIndex].serializedCountMethod
          );
          if (serializedMethod.renderMethod === "line") {
            const line2Point = array.find(
              (p) => p.text === serializedMethod.endPoint
            );
            if (line2Point) {
              const line2X = pos.x + line2Point.x * scale;
              const line2Y = pos.y + line2Point.y * scale;
              point.degree = findAngle(line2X, line2Y, pointX, pointY);
              ctx.strokeStyle = "red";
              ctx.lineWidth = 3;
              ctx.beginPath();
              ctx.moveTo(pointX, pointY);
              ctx.lineTo(line2X, line2Y);
              ctx.stroke();
            }
          } else if (serializedMethod.renderMethod === "perpendicular") {
            const point1 = array.find(
              (p) => p.text === serializedMethod.startPoint
            );
            const point2 = array.find(
              (p) => p.text === serializedMethod.endPoint
            );
            if (point1 && point2) {
              const scaledPoint1 = {
                x: pos.x + point1.x * scale,
                y: pos.y + point1.y * scale,
              };
              const scaledPoint2 = {
                x: pos.x + point2.x * scale,
                y: pos.y + point2.y * scale,
              };
              const scaledPoint = { x: pointX, y: pointY };
              const dx = scaledPoint2.x - scaledPoint1.x;
              const dy = scaledPoint2.y - scaledPoint1.y;
              if (dx === 0) {
                ctx.beginPath();
                ctx.moveTo(scaledPoint.x, scaledPoint.y);
                ctx.lineTo(scaledPoint.x, scaledPoint1.y);
                ctx.stroke();
              } else {
                const originalSlope = dy / dx;
                const originalIntercept =
                  scaledPoint1.y - originalSlope * scaledPoint1.x;
                const mPerpendicular = -dx / dy;
                const bPerpendicular =
                  scaledPoint.y - mPerpendicular * scaledPoint.x;
                const xIntersection =
                  (originalIntercept - bPerpendicular) /
                  (mPerpendicular - originalSlope);
                const yIntersection =
                  originalSlope * xIntersection + originalIntercept;
                ctx.beginPath();
                ctx.moveTo(scaledPoint.x, scaledPoint.y);
                ctx.lineTo(xIntersection, yIntersection);
                ctx.stroke();
              }
            }
          } else if (serializedMethod.renderMethod === "obtuseAngle") {
            const point1 = array.find(
              (p) => p.text === serializedMethod.startPoint1
            );
            const point2 = array.find(
              (p) => p.text === serializedMethod.endPoint1
            );
            const point3 = array.find(
              (p) => p.text === serializedMethod.startPoint2
            );
            if (point1 && point2 && point3) {
              const scaledPoint1 = {
                x: pos.x + point1.x * scale,
                y: pos.y + point1.y * scale,
              };
              const scaledPoint2 = {
                x: pos.x + point2.x * scale,
                y: pos.y + point2.y * scale,
              };
              const scaledPoint3 = {
                x: pos.x + point3.x * scale,
                y: pos.y + point3.y * scale,
              };
              const scaledPoint4 = { x: pointX, y: pointY };
            
              const intersection = getIntersection(
                scaledPoint1,
                scaledPoint2,
                scaledPoint3,
                scaledPoint4
              );
              if (intersection) {
                ctx.beginPath();
                ctx.arc(intersection.x, intersection.y, 5, 0, 2 * Math.PI);
                ctx.fillStyle = "red";
                ctx.fill();
                
                const obtuseAngle = getAngleBetweenLines(
                  scaledPoint1,
                  scaledPoint2,
                  scaledPoint3,
                  scaledPoint4
                );
                ctx.font = "14px Arial";
                ctx.fillStyle = "black";
                ctx.fillText(
                  `${serializedMethod.obtuseAngleName}, ${obtuseAngle.toFixed(
                    1
                  )}°`,
                  intersection.x + 10,
                  intersection.y - 10
                );
              }
            }
          } else if (serializedMethod.renderMethod === "bisector") {
            const point1 = array.find(
              (p) => p.text === serializedMethod.startPoint1
            );
            const point2 = array.find(
              (p) => p.text === serializedMethod.endPoint1
            );
            const point3 = array.find(
              (p) => p.text === serializedMethod.startPoint2
            );
            const point4 = array.find(
              (p) => p.text === serializedMethod.endPoint2
            );
            if (point1 && point2 && point3 && point4) {
              const origPoint1 = { x: point1.x, y: point1.y };
              const origPoint2 = { x: point2.x, y: point2.y };
              const origPoint3 = { x: point3.x, y: point3.y };
              const origPoint4 = { x: point4.x, y: point4.y };
              const origCurrentPoint = { x: point.x, y: point.y };
              
              const intersection = getIntersection(
                origPoint1,
                origPoint2,
                origPoint3,
                origPoint4
              );
              if (intersection) {
                const vectors = [
                  {
                    x: origPoint1.x - intersection.x,
                    y: origPoint1.y - intersection.y,
                  },
                  {
                    x: origPoint2.x - intersection.x,
                    y: origPoint2.y - intersection.y,
                  },
                  {
                    x: origPoint3.x - intersection.x,
                    y: origPoint3.y - intersection.y,
                  },
                  {
                    x: origPoint4.x - intersection.x,
                    y: origPoint4.y - intersection.y,
                  },
                ];
                
                const normVectors = vectors.map(normalize);
                
                const angles = [
                  getAngleBetween(vectors[0], vectors[2]),
                  getAngleBetween(vectors[0], vectors[3]),
                  getAngleBetween(vectors[1], vectors[2]),
                  getAngleBetween(vectors[1], vectors[3]),
                ];
                const maxAngle = Math.max(...angles);
                if (maxAngle <= 90) return;
                let finalBisector;
                if (angles[0] === maxAngle) {
                  finalBisector = normalize({
                    x: normVectors[0].x + normVectors[2].x,
                    y: normVectors[0].y + normVectors[2].y,
                  });
                } else if (angles[1] === maxAngle) {
                  finalBisector = normalize({
                    x: normVectors[0].x + normVectors[3].x,
                    y: normVectors[0].y + normVectors[3].y,
                  });
                } else if (angles[2] === maxAngle) {
                  finalBisector = normalize({
                    x: normVectors[1].x + normVectors[2].x,
                    y: normVectors[1].y + normVectors[2].y,
                  });
                } else if (angles[3] === maxAngle) {
                  finalBisector = normalize({
                    x: normVectors[1].x + normVectors[3].x,
                    y: normVectors[1].y + normVectors[3].y,
                  });
                }
                if (finalBisector) {
                  const toCurrentPoint = {
                    x: origCurrentPoint.x - intersection.x,
                    y: origCurrentPoint.y - intersection.y,
                  };
                  const dotProduct =
                    toCurrentPoint.x * finalBisector.x +
                    toCurrentPoint.y * finalBisector.y;
                  if (dotProduct < 0) {
                    finalBisector.x = -finalBisector.x;
                    finalBisector.y = -finalBisector.y;
                  }
                  const scaledIntersection = {
                    x: pos.x + intersection.x * scale,
                    y: pos.y + intersection.y * scale,
                  };
                  const lineLength = 15000;
                  const bisectorEnd = {
                    x:
                      scaledIntersection.x +
                      finalBisector.x * lineLength * scale,
                    y:
                      scaledIntersection.y +
                      finalBisector.y * lineLength * scale,
                  };
                  ctx.strokeStyle = "red";
                  ctx.lineWidth = 2;
                  ctx.beginPath();
                  ctx.moveTo(scaledIntersection.x, scaledIntersection.y);
                  ctx.lineTo(bisectorEnd.x, bisectorEnd.y);
                  ctx.stroke();
                }
              }
            }
          }
        }
      }

      if (point.type === "ruler1" && !hideLines) {
        const ruler2Point = array.find(
          (p, i) => i > index && p.type === "ruler2" && p.order === point.order
        );
        if (ruler2Point) {
          const line2X = pos.x + ruler2Point.x * scale;
          const line2Y = pos.y + ruler2Point.y * scale;
          point.degree = findAngle(line2X, line2Y, pointX, pointY);
          const midX = (pointX + line2X) / 2;
          const midY = (pointY + line2Y) / 2;
          const dx = ruler2Point.x - point.x;
          const dy = ruler2Point.y - point.y;
          const length = Math.sqrt(dx * dx + dy * dy);
          const rLength = length / realLength;
          ctx.strokeStyle = "red";
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(pointX, pointY);
          ctx.lineTo(line2X, line2Y);
          ctx.stroke();
          ctx.font = "12px Arial";
          ctx.fillStyle = "black";
          ctx.fillText(`${rLength.toFixed(1)}`, midX + 7, midY + 7);
        }
      }

      if (point.type === "angle1" && !hideLines) {
        const angle2Point = array.find(
          (p, i) => i > index && p.type === "angle2" && p.order === point.order
        );
        if (angle2Point) {
          const line2X = pos.x + angle2Point.x * scale;
          const line2Y = pos.y + angle2Point.y * scale;
          point.degree = findAngle(line2X, line2Y, pointX, pointY);
          ctx.strokeStyle = "red";
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(pointX, pointY);
          ctx.lineTo(line2X, line2Y);
          ctx.stroke();
        }
      }

      if (point.type === "angle2" && !hideLines) {
        const angle3Point = array.find(
          (p, i) => i > index && p.type === "angle3" && p.order === point.order
        );
        if (angle3Point) {
          const line2X = pos.x + angle3Point.x * scale;
          const line2Y = pos.y + angle3Point.y * scale;
          point.degree = findAngle(line2X, line2Y, pointX, pointY);
          ctx.strokeStyle = "red";
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(pointX, pointY);
          ctx.lineTo(line2X, line2Y);
          ctx.stroke();
        }
      }

      if (point.type === "angle3" && !hideLines) {
        const angle2Point = array.find(
          (p, i) => i < index && p.type === "angle2" && p.order === point.order
        );
        const angle1Point = array.find(
          (p, i) => i < index && p.type === "angle1" && p.order === point.order
        );
        if (angle1Point && angle2Point) {
          const p1X = pos.x + angle1Point.x * scale;
          const p1Y = pos.y + angle1Point.y * scale;
          const p2X = pos.x + angle2Point.x * scale;
          const p2Y = pos.y + angle2Point.y * scale;
          const p3X = pointX;
          const p3Y = pointY;
          const v1x = p1X - p2X;
          const v1y = p1Y - p2Y;
          const v2x = p3X - p2X;
          const v2y = p3Y - p2Y;
          const angleRad = Math.atan2(v2y, v2x) - Math.atan2(v1y, v1x);
          const angleDeg = angleRad * (180 / Math.PI);
          let angleDegNormalized = (angleDeg + 360) % 360;
          if (angleDegNormalized > 180)
            angleDegNormalized = 360 - angleDegNormalized;
          angle2Point.degree = angleDegNormalized;
          ctx.strokeStyle = "red";
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(pointX, pointY);
          ctx.lineTo(p2X, p2Y);
          ctx.stroke();
          ctx.strokeStyle = "blue";
          ctx.lineWidth = 2;
          ctx.beginPath();
          const radius = 20;
          const startAngle = Math.atan2(p1Y - p2Y, p1X - p2X);
          const endAngle = Math.atan2(p3Y - p2Y, p3X - p2X);
          const anticlockwise = angleDeg > 180;
          ctx.arc(p2X, p2Y, radius, startAngle, endAngle, anticlockwise);
          ctx.stroke();
          ctx.font = "12px Arial";
          ctx.fillStyle = "black";
          const textX = p2X;
          const textY = p2Y - radius - 5;
          ctx.fillText(
            `${angle2Point.degree.toFixed(0) + String.fromCharCode(176)}`,
            textX,
            textY
          );
        }
      }
    });

    // Handle currentPoint separately, only if it's not already in pointsCopy
    if (
      currentPoint &&
      !pointsCopy.some(
        (p) =>
          p.x === currentPoint.x &&
          p.y === currentPoint.y &&
          p.order === currentPoint.order
      )
    ) {
      const pointX = pos.x + currentPoint.x * scale;
      const pointY = pos.y + currentPoint.y * scale;
      drawPoint(pointX, pointY, "blue", 5);

      // Draw text for currentPoint
      ctx.font = "12px Arial";
      ctx.fillStyle = "black";
      ctx.fillText(
        `(${currentPoint.x.toFixed(0)}, ${currentPoint.y.toFixed(0)}, ${
          currentPoint.order
        }, ${currentPoint.text})`,
        pointX + 7,
        pointY + 7
      );

      // Handle connections for currentPoint
      if (currentPoint.type === "ruler2" && !hideLines) {
        const prevPoint = pointsCopy.find(
          (p) => p.type === "ruler1" && p.order === currentPoint.order
        );
        if (prevPoint) {
          const prevX = pos.x + prevPoint.x * scale;
          const prevY = pos.y + prevPoint.y * scale;
          ctx.strokeStyle = "red";
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(pointX, pointY);
          ctx.lineTo(prevX, prevY);
          ctx.stroke();
          currentPoint.degree = findAngle(prevX, prevY, pointX, pointY);
        }
      }
      if (currentPoint.type === "angle2" && !hideLines) {
        const prevPoint = pointsCopy.find(
          (p) => p.type === "angle1" && p.order === currentPoint.order
        );
        if (prevPoint) {
          const prevX = pos.x + prevPoint.x * scale;
          const prevY = pos.y + prevPoint.y * scale;
          ctx.strokeStyle = "red";
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(pointX, pointY);
          ctx.lineTo(prevX, prevY);
          ctx.stroke();
        }
      }
      if (currentPoint.type === "angle3" && !hideLines) {
        const angle2Point = pointsCopy.find(
          (p) => p.type === "angle2" && p.order === currentPoint.order
        );
        const angle1Point = pointsCopy.find(
          (p) => p.type === "angle1" && p.order === currentPoint.order
        );
        if (angle1Point && angle2Point) {
          const p1X = pos.x + angle1Point.x * scale;
          const p1Y = pos.y + angle1Point.y * scale;
          const p2X = pos.x + angle2Point.x * scale;
          const p2Y = pos.y + angle2Point.y * scale;
          const p3X = pointX;
          const p3Y = pointY;
          const v1x = p1X - p2X;
          const v1y = p1Y - p2Y;
          const v2x = p3X - p2X;
          const v2y = p3Y - p2Y;
          const angleRad = Math.atan2(v2y, v2x) - Math.atan2(v1y, v1x);
          const angleDeg = angleRad * (180 / Math.PI);
          let angleDegNormalized = (angleDeg + 360) % 360;
          if (angleDegNormalized > 180)
            angleDegNormalized = 360 - angleDegNormalized;
          angle2Point.degree = angleDegNormalized;
          ctx.strokeStyle = "red";
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(pointX, pointY);
          ctx.lineTo(p2X, p2Y);
          ctx.stroke();
          ctx.strokeStyle = "blue";
          ctx.lineWidth = 2;
          ctx.beginPath();
          const radius = 20;
          const startAngle = Math.atan2(p1Y - p2Y, p1X - p2X);
          const endAngle = Math.atan2(p3Y - p2Y, p3X - p2X);
          const anticlockwise = angleDeg > 180;
          ctx.arc(p2X, p2Y, radius, startAngle, endAngle, anticlockwise);
          ctx.stroke();
          ctx.font = "12px Arial";
          ctx.fillStyle = "black";
          const textX = p2X;
          const textY = p2Y - radius - 5;
          ctx.fillText(
            `${angle2Point.degree.toFixed(0) + String.fromCharCode(176)}`,
            textX,
            textY
          );
        }
      }
    }
  };

  type PointCoords = { x: number; y: number };

  const findPointByName = (pointName: any) => {
    if (typeof pointName !== "string") return pointName;
    return points.find((p) => p.text === pointName);
  };

  const evaluatePipe = (obj: any): any => {
    if (typeof obj === "string") return findPointByName(obj);
    if (typeof obj === "object" && "x" in obj) return obj;
    if (typeof obj === "number") return obj;
    if (obj.func && obj.func === "angle") {
      return acuteAngle(
        evaluatePipe(obj.pipe[0]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[1]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[2]) ?? { x: 200, y: 200 }
      );
    }
    if (obj.func && obj.func === "angleLines") {
      return acuteAngleLines(
        evaluatePipe(obj.pipe[0]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[1]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[2]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[3]) ?? { x: 200, y: 200 }
      );
    }
    if (obj.func && obj.func === "perpendicular") {
      return perpendicularPoint(
        evaluatePipe(obj.pipe[0]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[1]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[2]) ?? { x: 200, y: 200 }
      );
    }
    if (obj.func && obj.func === "distance") {
      return distanceBetweenPoints(
        evaluatePipe(obj.pipe[0]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[1]) ?? { x: 200, y: 200 },
        realLength
      );
    }
    if (obj.func && obj.func === "intersection") {
      return linesIntersectionPoint(
        evaluatePipe(obj.pipe[0]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[1]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[2]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[3]) ?? { x: 200, y: 200 }
      );
    }
    if (obj.func && obj.func === "parallel") {
      return findParallelPoint(
        evaluatePipe(obj.pipe[0]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[1]) ?? { x: 200, y: 200 },
        evaluatePipe(obj.pipe[2]) ?? { x: 200, y: 200 }
      );
    }
    if (obj.func && obj.func === "mul") {
      return (
        (evaluatePipe(obj.pipe[0]) ?? 0) * (evaluatePipe(obj.pipe[1]) ?? 0)
      );
    }
    if (obj.func && obj.func === "add") {
      return (
        (evaluatePipe(obj.pipe[0]) ?? 0) + (evaluatePipe(obj.pipe[1]) ?? 0)
      );
    }
    if (obj.func && obj.func === "compare") {
      return (
        (evaluatePipe(obj.pipe[0]) ?? 0) - (evaluatePipe(obj.pipe[1]) ?? 0)
      );
    }
  };

  const updatePoint = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect || !imgRef.current) return;
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;
    let isRuler2 = false;
    let isAngle2 = false;
    let isAngle3 = false;
    const img = imgRef.current;
    let relativeX = (mouseX - position.x) / scale;
    let relativeY = (mouseY - position.y) / scale;
    relativeX = Math.max(0, Math.min(relativeX, img.width));
    relativeY = Math.max(0, Math.min(relativeY, img.height));
    if (mode === "ruler") {
      if (
        points[points.length - 1] &&
        points[points.length - 1].type === "ruler1"
      ) {
        isRuler2 = true;
      }
    }
    if (mode === "angle") {
      if (
        points[points.length - 1] &&
        points[points.length - 1].type === "angle1"
      ) {
        isAngle2 = true;
      } else if (
        points[points.length - 1] &&
        points[points.length - 1].type === "angle2"
      ) {
        isAngle3 = true;
      }
    }
    setCurrentPoint({
      x: relativeX,
      y: relativeY,
      order: pointOrder,
      text: "Insert Text",
      type:
        mode === "point"
          ? "point"
          : mode === "ruler" && isRuler2
          ? "ruler2"
          : mode === "ruler"
          ? "ruler1"
          : mode === "angle" && isAngle2
          ? "angle2"
          : mode === "angle" && isAngle3
          ? "angle3"
          : mode === "angle"
          ? "angle1"
          : "unknown",
      degree: -1,
    });
  };

  const drawPoint = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect || !imgRef.current) return;
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;
    const img = imgRef.current;
    let relativeX = (mouseX - position.x) / scale;
    let relativeY = (mouseY - position.y) / scale;
    relativeX = Math.max(0, Math.min(relativeX, img.width));
    relativeY = Math.max(0, Math.min(relativeY, img.height));

    setPoints((prev) => [
      ...prev,
      {
        x: relativeX,
        y: relativeY,
        order: pointOrder,
        text: "Insert Text",
        type: "drawpoint",
        degree: -1,
      },
    ]);
  };

  const findAngle = (x1: number, y1: number, x2: number, y2: number) => {
    // Calculate the difference in x and y coordinates
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;

    // Use atan2 to get the angle in radians
    // atan2 handles all quadrants correctly
    const angleInRadians = Math.atan2(deltaY, deltaX);

    // Convert radians to degrees
    const angleInDegrees = angleInRadians * (180 / Math.PI);

    // Ensure the angle is between 0 and 360
    const normalizedAngle = (angleInDegrees + 360) % 360;

    return normalizedAngle;
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (mode === "drag" && e.button === 0) {
      setIsDragging(true);
      setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
    } else if (mode === "point" && e.button === 0) {
      setIsDrawing(true);
      updatePoint(e);
    } else if (mode === "draw" && e.button === 0) {
      setIsDrawing(true);
      if (newLine) {
        setPointOrder((p) => p + 1); // Increment order for new line
        setNewLine(false);
      }
    } else if (mode === "ruler" && e.button === 0) {
      setIsDrawing(true);
      updatePoint(e);
    } else if (mode === "angle" && e.button === 0) {
      setIsDrawing(true);
      updatePoint(e);
    } else if (mode !== "drag" && e.button === 2) {
      setIsDragging(true);
      setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (
      isDrawing &&
      (mode === "point" || mode === "ruler" || mode === "angle")
    ) {
      updatePoint(e);
    } else if (isDrawing && mode === "draw") {
      drawPoint(e);
    } else if (isDragging) {
      setPosition({ x: e.clientX - offset.x, y: e.clientY - offset.y });
    }
  };

  const handleMouseUp = () => {
    if (currentPoint) {
      if (mode !== "ruler" && mode !== "angle") setPointOrder((p) => p + 1);
      setPoints((prev) => [...prev, currentPoint]);
    }
    if (mode === "draw" && isDrawing) {
      setPointOrder((p) => p + 1);
      setNewLine(true);
    }
    if (mode === "ruler" && isDrawing) {
      if (
        points[points.length - 1] &&
        points[points.length - 1].type === "ruler1"
      )
        setPointOrder((p) => p + 1);
    }
    if (mode === "angle" && isDrawing) {
      if (
        points[points.length - 1] &&
        points[points.length - 1].type === "angle2"
      )
        setPointOrder((p) => p + 1);
    }

    setCurrentPoint(null);
    setIsDragging(false);
    setIsDrawing(false);
    setRedoBuffer([]);
  };

  return (
    <canvas
      ref={canvasRef}
      width={windowSize[0]}
      height={windowSize[1] - headerHeight}
      style={{
        display: "block",
        cursor: isDragging
          ? "grabbing"
          : mode === "point" ||
            mode === "draw" ||
            mode === "ruler" ||
            mode === "angle"
          ? "crosshair"
          : "grab",
        position: "absolute",
        top: headerHeight,
        left: 0,
        zIndex: -1,
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    />
  );
};

export default CanvasComponent;
