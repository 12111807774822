

type PointCoords = { x: number; y: number };
 
 
 
 const acuteAngle = (
    p0: PointCoords,
    c: PointCoords,
    p1: PointCoords
  ): number => {
    const p0c = Math.sqrt(Math.pow(c.x - p0.x, 2) + Math.pow(c.y - p0.y, 2)); // p0->c (b)
    const p1c = Math.sqrt(Math.pow(c.x - p1.x, 2) + Math.pow(c.y - p1.y, 2)); // p1->c (a)
    const p0p1 = Math.sqrt(Math.pow(p1.x - p0.x, 2) + Math.pow(p1.y - p0.y, 2)); // p0->p1 (c)
    return (
      (Math.acos((p1c * p1c + p0c * p0c - p0p1 * p0p1) / (2 * p1c * p0c)) *
        180) /
      Math.PI
    );
  };
  

  const acuteAngleLines = (
    A: PointCoords,
    B: PointCoords,
    C: PointCoords,
    D: PointCoords
  ): number => {
    //find vector components
    const dAx = B.x - A.x;
    const dAy = B.y - A.y;
    const dBx = D.x - C.x;
    const dBy = D.y - C.y;
    let angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
    if (angle < 0) {
      angle = angle * -1;
    }
    return Math.min(angle * (180 / Math.PI), 180 - angle * (180 / Math.PI));
  };

  const perpendicularPoint = (
    p1: PointCoords,
    p2: PointCoords,
    p3: PointCoords
  ): PointCoords => {
    // Vector from P1 to P2 (line direction vector)
    const lineVec: PointCoords = { x: p2.x - p1.x, y: p2.y - p1.y };

    // Vector from P1 to P3
    const pointVec: PointCoords = { x: p3.x - p1.x, y: p3.y - p1.y };

    // Dot product of the vector from P1 to P3 and the line vector
    const dotProduct = pointVec.x * lineVec.x + pointVec.y * lineVec.y;

    // Magnitude squared of the line vector
    const lineVecMagSquared = lineVec.x * lineVec.x + lineVec.y * lineVec.y;

    // Projection factor
    const projectionFactor = dotProduct / lineVecMagSquared;

    // Perpendicular point P4 (the projected point on the line)
    const perpendicular: PointCoords = {
      x: +(p1.x + projectionFactor * lineVec.x).toFixed(0),
      y: +(p1.y + projectionFactor * lineVec.y).toFixed(0),
    };

    return perpendicular;
  };

  const distanceBetweenPoints = (p1: PointCoords, p2: PointCoords, realLength: number): number => {
    // Calculate the Euclidean distance
    return (
      Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)) /
      realLength
    );
  };

  const linesIntersectionPoint = (
    A: PointCoords,
    B: PointCoords,
    C: PointCoords,
    D: PointCoords
  ): PointCoords => {
    const denom = (D.y - C.y) * (B.x - A.x) - (D.x - C.x) * (B.y - A.y);
    
    if (denom == 0) {
      return { x: -1, y: -1 };
    }

    const ua = ((D.x - C.x) * (A.y - C.y) - (D.y - C.y) * (A.x - C.x)) / denom;

    return {
      x: A.x + ua * (B.x - A.x),
      y: A.y + ua * (B.y - A.y),
    };
  };

  function findParallelPoint(
    p1: PointCoords,
    p2: PointCoords,
    p3: PointCoords
  ): PointCoords {
    // Calculate the direction vector of the line formed by p1 and p2
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;

    // The new point p4 is calculated by adding the direction vector to p3
    const p4: PointCoords = {
      x: p3.x + dx,
      y: p3.y + dy,
    };

    return p4;
  }

  const getIntersection = (
    p1: { x: number; y: number },
    p2: { x: number; y: number },
    p3: { x: number; y: number },
    p4: { x: number; y: number }
  ) => {
    const A1 = p2.y - p1.y;
    const B1 = p1.x - p2.x;
    const C1 = A1 * p1.x + B1 * p1.y;
    const A2 = p4.y - p3.y;
    const B2 = p3.x - p4.x;
    const C2 = A2 * p3.x + B2 * p3.y;
    const determinant = A1 * B2 - A2 * B1;
    if (determinant === 0) return null;
    return {
      x: (C1 * B2 - C2 * B1) / determinant,
      y: (A1 * C2 - A2 * C1) / determinant,
    };
  };

  const getAngleBetween = (
    v1: { x: number; y: number },
    v2: { x: number; y: number }
  ) => {
    const dot = v1.x * v2.x + v1.y * v2.y;
    const mag1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
    const mag2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y);
    const rad = Math.acos(Math.min(1, Math.max(-1, dot / (mag1 * mag2))));
    return (rad * 180) / Math.PI;
  };

  const getAngleBetweenLines = (
    p1: { x: number; y: number },
    p2: { x: number; y: number },
    p3: { x: number; y: number },
    p4: { x: number; y: number }
  ) => {
    const v1 = { x: p2.x - p1.x, y: p2.y - p1.y };
    const v2 = { x: p4.x - p3.x, y: p4.y - p3.y };
    const dotProduct = v1.x * v2.x + v1.y * v2.y;
    const magV1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
    const magV2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y);
    const angleRad = Math.acos(dotProduct / (magV1 * magV2));
    const angleDeg = (angleRad * 180) / Math.PI;
    return angleDeg > 90 ? angleDeg : 180 - angleDeg;
  };

  const normalize = (vec: { x: number; y: number }) => {
    const length = Math.sqrt(vec.x * vec.x + vec.y * vec.y);
    return length !== 0
      ? { x: vec.x / length, y: vec.y / length }
      : { x: 0, y: 0 };
  };

  export {
    acuteAngle,
    acuteAngleLines,
    perpendicularPoint,
    distanceBetweenPoints,
    linesIntersectionPoint,
    findParallelPoint,
    getIntersection,
    getAngleBetween,
    getAngleBetweenLines,
    normalize,
  };